import type { Epic } from 'behavior/types';
import { combineEpics } from 'redux-observable';
import { EMPTY, interval } from 'rxjs';
import {
  first,
  pluck,
  map,
  mergeMap,
  mapTo,
} from 'rxjs/operators';

import { initializeAnalyticsTrackers, notifyDataLayerReady } from './actions';
import ecommerceTracking from './epic.ecommerce';
import userTracking from './epic.user';

const init: Epic<never> = (_, state$, _deps) => state$.pipe(
  pluck('settings'),
  first(settings => settings?.loaded),
  pluck('analytics'),
  map(initializeAnalyticsTrackers),
);

const dataLayerReady: Epic<never> = (_action$, state$, { scope }) => {
  if (scope === 'SERVER')
    return EMPTY;

  return state$.pipe(
    first(state => state.analytics.isTrackingEnabled),
    mergeMap(state => {
      const dataLayerName = state.analytics.trackers![0].dataLayerName;
      return interval(500).pipe(
        first(_ => (<any>window)[dataLayerName]),
        mapTo(notifyDataLayerReady()),
      );
    }),
  );
};

export default combineEpics(
  init,
  ecommerceTracking,
  userTracking,
  dataLayerReady,
);