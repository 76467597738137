import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  CHECKOUT_ADDITIONAL_INFO_SAVE,
  DELETE_CHECKOUT_ATTACHMENT,
} from './actions';
import { getSaveAdditionalInfoMutation, getRemoveCheckoutAttachmentByIdMutation } from './queries';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {

    const checkoutAdditionalInfoSave$ = action$.pipe(
      ofType(CHECKOUT_ADDITIONAL_INFO_SAVE),
      switchMap(action => waitForSubmit(() => {
        // 'Update' with empty object is used to trigger redux state update, so it will result in update of object reference in hook(s) dependencies.
        const emptyInfoUpdatedAction = checkoutInfoUpdated({});
        let fileNames = [], files = {};
        if (action.payload.fileAttachments) {
          files = { files: action.payload.fileAttachments, retries: 0 };
          fileNames = getFileNames(action.payload.fileAttachments);
        }

        const input = { referenceNo: action.payload.referenceNo, comment: action.payload.comment, deliveryDate: action.payload.deliveryDate, onHold: action.payload.onHold, fileNames };
        return api.graphApi(getSaveAdditionalInfoMutation(!!state$.value.page.info?.quote), { input: input }, files).pipe(
          map(({ checkout }) => checkoutInfoUpdated(checkout.additionalInfo.save.info)),
          catchApiErrorWithToast(undefined, of(emptyInfoUpdatedAction)),
          retryWithToast(action$, logger),
        );
      })),
    );

    const deleteCheckoutAttachment$ = action$.pipe(
      ofType(DELETE_CHECKOUT_ATTACHMENT),
      switchMap(action => waitForSubmit(() => {

        const emptyInfoUpdatedAction = checkoutInfoUpdated({});

        return api.graphApi(getRemoveCheckoutAttachmentByIdMutation(!!state$.value.page.info?.quote), { input: { "id": action.payload } }).pipe(
          map(({ checkout }) => checkoutInfoUpdated(checkout.additionalInfo.removeCheckoutAttachmentById.info)),
          catchApiErrorWithToast(undefined, of(emptyInfoUpdatedAction)),
          retryWithToast(action$, logger),
        );

      })),
    );

    return merge(checkoutAdditionalInfoSave$, deleteCheckoutAttachment$);

  };
}

const getFileNames = fileAttachments => {
  const fileNames = [];
  for (const file of fileAttachments)
    fileNames.push(file.name);
  
  return fileNames;
};