import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRows } from 'components/containers';
import { tablet as defaultTablet } from './defaultContent';

const TabletHeaderTemplate = () => {
  const tablet = useSelector(state => state.header.tablet);

  return renderRows(tablet ?? defaultTablet);
};

export default memo(TabletHeaderTemplate);
