export const enum EventSource {
  SearchInput = 'Search bar',
  CheckoutOffers = 'Checkout offers',
  SearchResult = 'Search results',
  LastViewedProducts = 'Last viewed products',
  LastViewedProductsPage = 'Last viewed products page',
  ShoppingCartPage = 'Shopping cart page',
  ShoppingCartMiniature = 'Shopping cart miniature',
  RelatedProducts = 'Related products',
  CompareProductsPage = 'Compare products page',
  CompareProductsWidget = 'Compare products widget',
  OrderTemplate = 'Order template',
  SalesAgreement = 'Sales agreement',
  QuickOrder = 'Quick order',
  ReturnOrder = 'Return order',
  WishList = 'Wish list',
}

export type CustomEventSource = {
  value: string;
};

type TrackerSettings = {
  type: TrackerType;
  containerId: string;
};

export type AnalyticsSettings = {
  trackers: TrackerSettings[];
};

export type TrackerInfo = {
  type: TrackerType;
  containerId: string;
  dataLayerName: 'dataLayer';
};

export type TrackerType =
  | 'GTM' // Google Tag Manager
  | 'PWK' // Piwik Pro
;

export type Uom = {
  id: string;
  description?: string;
};

export type ProductCategories = string | Array<string> | Array<{ name: string }>;
