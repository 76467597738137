import type { TrackerInfo } from './types';
import {
  AnalyticsAction,
  ANALYTICS_TRACKERS_INITIALIZE,
  ANALYTICS_DATALAYER_READY,
  InitializeAnalyticsTrackersAction,
} from './actions';
import trackerFactory from './trackerFactory';

type AnalyticsState = {
  isTrackingEnabled: boolean;
  isDataLayerReady?: boolean;
  trackers?: TrackerInfo[];
};

const initialState: AnalyticsState = {
  isTrackingEnabled: false,
};

export default (state: AnalyticsState = initialState, action: AnalyticsAction): AnalyticsState => {
  switch (action.type) {
    case ANALYTICS_TRACKERS_INITIALIZE:
      return onTrackersInitialize(state, action);
    case ANALYTICS_DATALAYER_READY:
      return onDataLayerReady(state);
    default: return state;
  }
};

function onTrackersInitialize(state: AnalyticsState, { payload }: InitializeAnalyticsTrackersAction): AnalyticsState {
  if (state.isTrackingEnabled)
    return state;

  const trackers = trackerFactory(payload);
  if (!trackers || !trackers.length)
    return state;

  return {
    ...state,
    isTrackingEnabled: true,
    trackers,
  };
}

function onDataLayerReady(state: AnalyticsState): AnalyticsState {
  return {
    ...state,
    isDataLayerReady: true,
  };
}
