import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import type { Api } from 'utils/api';
import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { loadSystemPageQuery } from '../system/queries';
import { getBackTo, initComponent } from '../helpers';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { of } from 'rxjs';
import { map, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType } from 'behavior/settings';

const handler: Handler<LoginRouteData, LoginPage> = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreLoginPage(routeData, state$)
        : loadSystemLoginPage(routeData, state$, api),
    ),
  );
};

export default handler;

function loadClosedStoreLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>) {
  return of({
    emptyLayout: true,
    backTo: _getBackTo(routeData, state$),
  }).pipe(
    initComponent(PageComponentNames.ClosedStoreLogin),
  );
}

function loadSystemLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>, api: Api) {
  return api.graphApi<LoginPageResponse>(loadSystemPageQuery('login')).pipe(
    pluck('pages', 'login'),
    map(page => {
      if (!page)
        return null;

      return {
        page: {
          component: PageComponentNames.Login as const,
          backTo: routeData.options && routeData.options.backTo || _getBackTo(routeData, state$),
          ...page,
        },
      };
    }),
    initSystemPageContent(),
  );
}

function _getBackTo(routeData: LoginRouteData, state$: StateObservable<AppState>) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.Registration,
    RouteName.ResetPassword,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}

type PublicStoreLoginPage = SystemPage & {
  component: PageComponentNames.Login;
  backTo?: BackTo;
};

type LoginRouteData = {
  routeName: RouteName.Login;
  params?: {
    language: number;
  };
  options?: {
    backTo?: BackTo;
  };
};

type LoginPageResponse = {
  pages: {
    login: SystemPageData;
  };
};

type ClosedStoreLoginPage = {
  component: PageComponentNames.ClosedStoreLogin;
  emptyLayout: boolean;
  backTo?: BackTo;
};

type LoginPage = ClosedStoreLoginPage | PublicStoreLoginPage;
