import type { Page } from 'behavior/types';
import type { Location } from './types';
import { createLocation } from './helpers';
import type { RouteName, RouteData, SystemRouteData } from 'routes';

export const NAVIGATION_REQUESTED = '@@router/NAVIGATION_REQUESTED' as const;
export const navigationRequested = (location: Location, statusCode?: number, routeData?: RouteData, replaceHistory = false) => ({
  type: NAVIGATION_REQUESTED,
  payload: { location, statusCode, routeData, replaceHistory },
});

export const NAVIGATING = '@@router/NAVIGATING' as const;
export const startNavigation = (location: Location, routeData: RouteData) => ({
  type: NAVIGATING,
  payload: { location, routeData },
});

export const NAVIGATED = '@@router/NAVIGATED' as const;
export const completeNavigation = (location: Location, routeData: RouteData, page: Page) => ({
  type: NAVIGATED,
  payload: {
    location,
    routeData,
    page,
  },
});

export const INIT = '@@router/INIT' as const;
export const initialize = (location: Location) => ({
  type: INIT,
  payload: { location },
});

const statusCodesSet = new Set([301, 401]);

export const redirectTo = (url: string, statusCode = 302, routeData?: RouteData) => {
  const location = createLocation(url);
  return navigationRequested(location, statusCode, routeData, statusCodesSet.has(statusCode));
};

export const STATUS_CODE_RESOLVED = '@@router/STATUS_CODE_RESOLVED' as const;
export const statusCodeResolved = (statusCode: number) => ({ type: STATUS_CODE_RESOLVED, payload: { statusCode } });

export const GO_BACK = '@@router/GO_BACK' as const;
export const goBack = () => ({ type: GO_BACK });

export const NAVIGATE_TO_PREVIOUS = '@@router/NAVIGATE_TO_PREVIOUS' as const;
export const navigateToPrevious = (ignoredRouteNames: RouteName[] = [], fallbackRoute?: SystemRouteData) => ({
  type: NAVIGATE_TO_PREVIOUS,
  payload: {
    ignoredRouteNames,
    fallbackRoute,
  },
});

export const RELOAD_LOCATION = '@@router/RELOAD_LOCATION' as const;
export const reloadLocation = () => ({ type: RELOAD_LOCATION });

export const REWRITE_TO = '@@router/REWRITE_TO' as const;
export const rewriteTo = (routeData: RouteData, omitScroll?: boolean) => ({ type: REWRITE_TO, payload: { routeData, omitScroll } });

export const SAVE_SCROLL_POSITION = '@@router/SAVE_SCROLL_POSITION' as const;
export const saveScrollPosition = (value: number, screenWidth: number) => ({ type: SAVE_SCROLL_POSITION, payload: { value, screenWidth } });

export type RoutingAction = ReturnType<
  | typeof navigationRequested
  | typeof startNavigation
  | typeof completeNavigation
  | typeof initialize
  | typeof redirectTo
  | typeof statusCodeResolved
  | typeof goBack
  | typeof reloadLocation
  | typeof saveScrollPosition
>;
