export const sortByIds = (products, ids) => {
  if (!products || !products.length)
    return [];
  return ids.map(id => products.find(p => p.id.toLocaleUpperCase() === id.toLocaleUpperCase())).filter(Boolean);
};

export const buildSpecificationsModel = ({ specifications }, products) => {
  if (!products || !products.length || !specifications || !specifications.length)
    return undefined;

  const specs = [];
  specifications.forEach(({ key }) => {
    const values = [];
    let name = null;
    let hasDifferences = false;
    let sanaTextKey = null;

    products.forEach(p => {
      const spec = p.specifications && p.specifications.find(s => s.key === key);

      if (spec) {
        values.push({
          sanaTextKey: spec.valueTextKey,
          value: spec.value,
        });
        name = spec.name;
        sanaTextKey = spec.titleTextKey;
      } else {
        values.push({
          sanaTextKey: null,
          value: null,
        });
      }
      hasDifferences = !values.every(v => v.value === values[0].value);
    });

    if (values.some(v => v.value !== null)) {
      specs.push({
        key,
        name,
        values,
        sanaTextKey,
        hasDifferences,
      });
    }
  });

  return specs.length ? specs : undefined;
};

export const removeProductFromSpecificationsModel = (specifications, productIndex) => {
  if (!specifications || !specifications.length)
    return specifications;

  specifications.forEach(spec => {
    spec.values && spec.values.splice(productIndex, 1);
    spec.hasDifferences = !spec.values.every(v => v.value === spec.values[0].value);
  });

  return specifications.filter(spec => spec.values.some(v => v.value !== null));
};