import documentEpic from './epic.document';
import reorderEpic from './epic.reorder';
import quoteEpic from './epic.quote';
import editEpic from './epic.edit';
import orderAuthorizationEpic from './epic.authorization';
import releaseEpic from './epic.release';

import { combineEpics } from 'redux-observable';

export default combineEpics(documentEpic, reorderEpic, quoteEpic, editEpic, orderAuthorizationEpic, releaseEpic);
