import {
  DOCUMENT_RECEIVED,
  DOCUMENT_REORDER_REQUESTED,
  DOCUMENT_REORDER_RECEIVED,
  QUOTE_STOCK_RECEIVED,
  QUOTE_PROMOTION_RESULT_RECEIVED,
  DOCUMENT_EDIT_REQUESTED,
  DOCUMENT_EDIT_RECEIVED,
  ORDER_AUTHORIZATION_RESULT_RECEIVED,
  LINES_TO_RELEASE,
  DOCUMENT_RELEASE_RECEIVED,
} from './actions';
import { createReducer } from 'utils/redux';
import { isQuoteInStock, setMarkedForReleaseToLines } from './helpers';
import { DocumentType } from 'behavior/documents';

export default createReducer(null, {
  [DOCUMENT_RECEIVED]: onDocumentReceived,
  [DOCUMENT_REORDER_REQUESTED]: onReorder,
  [DOCUMENT_REORDER_RECEIVED]: onReorderReceived,
  [QUOTE_STOCK_RECEIVED]: onQuoteStockReceived,
  [QUOTE_PROMOTION_RESULT_RECEIVED]: onQuotePromotionResultReceived,
  [DOCUMENT_EDIT_REQUESTED]: onEdit,
  [DOCUMENT_EDIT_RECEIVED]: onEditReceived,
  [ORDER_AUTHORIZATION_RESULT_RECEIVED]: onOrderAuthorizationResultReceived,
  [LINES_TO_RELEASE]: onLinesToRelease,
  [DOCUMENT_RELEASE_RECEIVED]: onDocumentReleaseReceived,
});

function onDocumentReceived(state, action) {
  const { document: payloadDocument, documentType, appendData } = action.payload;

  const document = appendData
    ? {
      ...state.document,
      ...payloadDocument,
    }
    : payloadDocument;

  if (document && documentType === DocumentType.Quote)
    document.hasStock = isQuoteInStock(document);

  return {
    ...state,
    document,
  };
}

function onReorder(state, _action) {
  return { ...state, reorderResult: null };
}

function onReorderReceived(state, action) {
  const { result: reorderResult } = action.payload;

  return {
    ...state,
    reorderResult,
  };
}

function onEdit(state, _action) {
  return { ...state, editResult: null };
}

function onEditReceived(state, action) {
  const { result: editResult } = action.payload;

  return {
    ...state,
    editResult,
  };
}

function onQuoteStockReceived(state, action) {
  const { quote } = action.payload;

  if (!quote)
    return state;

  return {
    ...state,
    document: {
      ...state.document,
      hasStock: isQuoteInStock(quote, true),
    },
  };
}

function onQuotePromotionResultReceived(state, action) {
  return {
    ...state,
    promotionResult: action.payload,
  };
}

function onOrderAuthorizationResultReceived(state, action) {
  const { authorizationActionResultStatus, authorizationInfo } = action.payload;
  return {
    ...state,
    document: {
      ...state.document,
      authorizationInfo,
      authorizationActionResultStatus,
    },
  };
}

function onLinesToRelease(state, action) {
  const { lineNo } = action.payload;
  const { linesToRelease }  = state.document;

  linesToRelease.indexOf(lineNo) === -1 ?
    linesToRelease.push(lineNo) :
    linesToRelease.splice(linesToRelease.indexOf(lineNo), 1);

  return {
    ...state,
    document: {
      ...state.document,
      linesToRelease,
    },
  };
}

function onDocumentReleaseReceived(state, action) {
  const { linesReleased } = action.payload;

  if (!linesReleased || linesReleased.length <= 0)
    return state;
      
  return {
    ...state,
    document: {
      ...state.document,      
      lines: setMarkedForReleaseToLines(state.document.lines, linesReleased),
      linesToRelease: [],
    },
  };
}