export default Object.freeze([
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': '#E6E6E6',
      'radius': null,
      'style': 'solid',
      'width': '0 0 1px 0',
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 12,
          'md': 12,
          'sm': 12,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': '2z8m94ivc',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'topLevel_AllCaps': false,
              'topLevel_ShowActiveItemUnderline': true,
              'subLevel_AllCaps': false,
              'subLevel_ShowArrowInFrontOfSubItem': false,
            },
            'name': 'MobileMainMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'CENTER',
            'id': 'ldzh25tcw',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'Logo',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': true,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'vtuopctu0',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'box_BorderStyle': 1,
              'box_BorderWidth': '1px',
              'box_CornerRadius': '2px',
              'box_ThemeFontSize': 'Regular',
              'button_Layout': 0,
              'button_ThemeFontSize': 'Regular',
              'button_CornerRadius': '0px 2px 2px 0px',
              'suggestions_ShowThumbnails': true,
              'suggestions_ThemeFontSize': 'Regular',
            },
            'name': 'SearchBar',
            'packId': null,
            'spacing': {
              'margin': '0 16px',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': true,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'sjvakgtzn',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'ProductSuggestions',
            'packId': null,
            'spacing': {
              'margin': '0 16px 0 0',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'w17los794',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'toggle_Presentation': 0,
              'toggle_FontSize': 'Regular',
              'toggle_AllCaps': false,
              'content_ShowFlags': true,
              'content_ShowExpandIcon': true,
              'content_AllCaps': false,
              'content_ItemFontSize': 'Regular',
            },
            'name': 'DesktopLanguageSelector',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'fkdvr89im',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'toggle_Presentation': 0,
              'toggle_ThemeFontSize': 'Regular',
              'toggle_AllCaps': false,
              'content_ShowExpandIcon': true,
              'content_ThemeFontSize': 'Regular',
            },
            'name': 'DesktopAccountMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'd5ftls6sa',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'showMiniatureOnBasket': false,
              'showSubtotal': false,
              'basketLink_Layout': 3,
              'basketLink_ThemeFontSize': 'Regular',
              'basketLink_AllCaps': true,
              'content_ShowExpandIcon': false,
              'content_ThemeFontSize': 'Regular',
              'content_ShowThumbnails': true,
              'heading_ThemeFontSize': 'Heading4',
              'heading_AllCaps': true,
              'footer_BasketLinkStyle': 0,
              'footer_CheckoutLinkStyle': 2,
              'footer_SwapLinksPosition': false,
            },
            'name': 'DesktopBasketSummary',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'u6e37eunt',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': true,
    'heroEffect': null,
    'id': 'ittw743g6',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': true,
      'margin': null,
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  },
]);
