import type { Settings } from './types';
import { SettingsAction, SETTINGS_LOADED, SETTINGS_UPDATING } from './actions';
import { mergeSettings } from './helpers';

const initialState = {
  loaded: false as const,
};

type State = typeof initialState | ({
  loaded: true;
  updating: boolean;
  error?: boolean;
} & Settings);

export default function reducer(state: State = initialState, action: SettingsAction) {
  switch (action.type) {
    case SETTINGS_LOADED:
      if (!action.payload)
        return { ...state, error: true, updating: false };

      return mergeSettings({ ...state, error: false, loaded: true, updating: false }, action.payload) as State;
    case SETTINGS_UPDATING:
      return { ...state, updating: true };
    default:
      return state;
  }
}
