import { initialState } from './constants';
import { BASKET_ERROR_MODE_ENABLED } from '../actions.errorMode';

const reducer = (state = initialState, action) => {
  if (action.type === BASKET_ERROR_MODE_ENABLED)
    return {
      ...state,
      isErrorMode: action.payload.enabled,
    };

  return state;
};

export default reducer;
