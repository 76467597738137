import { createLoadPageQuery } from './queries';
import { map, tap, filter, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestPage } from './actions';
import { createApiVariables, sortProducts } from 'behavior/products/lastViewedTracking/helpers';
import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';

export default ({ params }, state$, { api, localStorage, scope }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('lastViewedProducts')).pipe(
      map(({ pages: { lastViewedProducts: page } }) => {
        if (!page)
          return null;
        
        page.component = PageComponentNames.LastViewedProducts;
        page.lastViewed = Array.from(Array(3)).map((_, index) => ({
          id: (index + 1).toString(),
          url: '',
          title: '',
          isOrderable: true,
          productConfiguratorInfo: {},
        }));

        return { page };
      }),
      initSystemPageContent(),
    );
  }

  const handle = ({ settings }) => {
    const variables = settings.lastViewedEnabled ? createApiVariables(localStorage) : undefined;
    if (variables)
      variables.loadCategories = state$.value.analytics && state$.value.analytics.isTrackingEnabled;
    
    const query = createLoadPageQuery({
      getProducts: variables && variables.options,
      isInsiteEditor: state$.value.insiteEditor.initialized,
    });

    return api.graphApi(query, variables).pipe(
      map(data => {
        const page = data.pages.lastViewedProducts;
        if (!page)
          return null;

        const products = data.catalog
          && data.catalog.products && data.catalog.products.products;
        const lastViewed = scope === 'CLIENT' ? sortProducts(products, variables) : null;

        return { ...page, lastViewed };
      }),
      initComponent(PageComponentNames.LastViewedProducts),
      initSystemPageContent(),
      tap(result => {
        if (variables)
          result.action$ = of(requestPage(true));
      }),
    );
  };

  if (state$.value.settings.loaded)
    return handle(state$.value);

  return state$.pipe(
    filter(({ settings }) => settings.loaded),
    first(),
    switchMap(handle),
  );
};
