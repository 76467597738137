import type { Scope } from 'utils/app';
import type { Handler } from '../types';
import type { WebsiteRedirectData, WebsiteRedirectDataParams } from './types';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { redirectTo } from 'behavior/routing';
import { destroyApp } from 'behavior/app';

const shouldRedirect = (scope: Scope) => (params: WebsiteRedirectDataParams | null | undefined): params is WebsiteRedirectDataParams => {
  return (params !== null && params !== undefined) && (scope === 'CLIENT' || (scope === 'SERVER' && params.routeName !== undefined));
};

const handler: Handler<WebsiteRedirectData, null> = ({ params }, _state$, { scope }) => {
  return of(params).pipe(
    filter(shouldRedirect(scope)),
    map(({ url: redirectUrl, routeName, params, permanent }) => {
      if (!routeName && scope === 'CLIENT') {
        (<any>window).location = redirectUrl;

        return {
          redirectUrl,
          action$: of(destroyApp()),
        };
      }

      const statusCode = permanent ? 301 : 302;
      return {
        redirectUrl,
        statusCode,
        action$: of(redirectTo(redirectUrl, statusCode, { routeName: routeName!, params })),
      };
    }),
  );
};

export default handler;
