import type { SystemPage } from './types';
import type { PageComponentNames } from '../componentNames';
import { loadSystemPageQuery } from './queries';
import { initComponent } from 'behavior/pages/helpers';
import { initPageContent } from './helpers';
import { decapitalize } from 'utils/helpers';
import { pluck, tap } from 'rxjs/operators';
import { RouteData } from 'routes';
import { Handler } from '../types';

export default () => {
  let transformedName: string;

  const handler: Handler<RouteData, SystemPage> = ({ routeName, options }, _, { api }) => {
    if (!transformedName)
      transformedName = decapitalize(routeName) as string;

    return api.graphApi(loadSystemPageQuery(transformedName)).pipe(
      pluck('pages', transformedName),
      initComponent(transformedName as PageComponentNames),
      tap(data => {
        if (!data || !data.page)
          return;

        data.page = initPageContent(data.page);
        data.page.options = options;
        data.page.backTo = options && options.backTo;
      }),
    );
  };

  return handler;
};
