import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { documentReleaseReceived, DOCUMENT_RELEASE } from '../actions';
import { releaseMutation } from '../queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default (action$, _state$, dependencies) =>
  action$.pipe(
    ofType(DOCUMENT_RELEASE),    
    exhaustMap(({ payload }) =>
      dependencies.api.graphApi(releaseMutation,
        { input: payload },
        { retries: 0 },
      ).pipe( 
        pluck('documents', 'release'),
        mergeMap(({ result }) => {
          
          const actions = [
            unsetLoadingIndicator(),
          ];

          actions.push(documentReleaseReceived(result));
          return actions;          
        }),
        retryWithToast(action$, dependencies.logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
