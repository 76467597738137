import { camelCase } from 'lodash';

export function capitalize(text?: string | null) {
  if (!text)
    return text;

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function decapitalize(text?: string) {
  if (!text)
    return text;

  return text.charAt(0).toLowerCase() + text.slice(1);
}

export function arrayToObject<T, K extends keyof any>(items: T[], keySelector: (item: T) => K): Record<K, T>;
export function arrayToObject<T, K extends keyof any, V>(items: T[], keySelector: (item: T) => K, valueSelector: (item: T) => V): Record<K, V>;

export function arrayToObject<T, K extends keyof any, V>(items: T[], keySelector: (item: T) => K, valueSelector?: (item: T) => V) {
  return items.reduce((m, item) => {
    m[keySelector(item)] = valueSelector ? valueSelector(item) : item;
    return m;
  }, {} as Record<K, T | V>);
}

export function stripHtmlTags(html?: string | null): string {
  if (!html) {
    return '';
  }
  return html.replace(/(<[A-Za-z!/?][^><]*>)+/gm, ' ').trim();
}

/**
 * Trims and replaces duplicated whitespaces and new line characters by single white space
 * @param {string} text - Formatted text
 * @returns {string} - stripped and trimmed text
 */
export function stripFormatting(text?: string | null): string {
  if (!text) {
    return '';
  }

  return text.trim().replace(/[\n\t\r\s]+/gm, ' ');
}

export function stripHtmlTagsAndFormatting(text?: string | null): string {
  return stripFormatting(stripHtmlTags(text));
}

/**
 * Convert styles string to object
 * @param {string} styles - styles string
 * @returns {object} - styles object
 */
export function stylesToJSON(styles: string): Record<string, string> {
  const object: Record<string, string> = {};
  const attributes = styles.split(';');
  for (const entry of attributes) {
    if (entry && entry.indexOf(':') > 0) {
      const pair = entry.split(':');
      object[camelCase(pair[0])] = pair[1].trim();
    }
  }

  return object;
}

export function generateKey() {
  return Math.random().toString(36).substr(2, 9);
}

/**
 * Escapes special characters for RegExp query
 * @param {string} regExpString - RegExp query string
 * @returns {string} - RegExp query with escaped special characters
 */
export function escapeRegexCharacters(regExpString: string): string {
  return regExpString.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

/**
 * Checks whether two arrays has at least one shared element
 * @param {Array} arrA - First array to check
 * @param {Array} arrB - Second array to check
 * @returns {boolean} - Flag indicating whether any equal element found
 */
export function anyEqual(arrA?: Array<unknown> | null, arrB?: Array<unknown> | null): boolean {
  if (!arrA || !arrB)
    return false;

  if (!arrA.length || !arrB.length)
    return false;

  return arrA.some(el => arrB.includes(el));
}

/**
 * Finds intersection between two arrays
 * @param {Array} arrA - First source array
 * @param {Array} arrB - Second source array
 * @returns {Array} - Resulting intersection
 */
export function intersect<T1, T2>(arrA?: Array<T1> | null, arrB?: Array<T2> | null): Array<T1 & T2> {
  if (!arrA || !arrB)
    return [];

  const setA = new Set(arrA);
  const isInA = setA.has.bind(setA);
  return arrB.filter((i: unknown) => isInA(i as T1)) as Array<T1 & T2>;
}

const invalidIdCharactersRegexp = /^[^a-z]+|[^\w:.-]+/gi;

export function sanitizeId(originalId: string, replacement = '_'): string {
  return originalId.replace(invalidIdCharactersRegexp, replacement);
}

const invalidClassNameCharactersRegexp = /[^\w-]/gi;

export function sanitizeClassName(originalClassName: string, replacement = '_'): string {
  return originalClassName.replace(invalidClassNameCharactersRegexp, replacement);
}

/**
 * Determines if any modifier key was pressed when KeyboardEvent occurred
 * @param {KeyboardEvent} e - KeyboardEvent
 * @returns {boolean} - if any modifier key was pressed
 */
export function isModifierKeyPressed(e: KeyboardEvent): boolean {
  return e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
}

/**
 * Creates a flattened array of values.
 * @param {Array} items The collection to iterate over
 * @param {Function} collectionSelector A transform function to apply to each source element.
 * @param {Function} resultSelector A transform function to apply to each result element.
 * @returns {Array} Returns the new flattened array.
 */
export function flatMap<T, U>(items: T[], collectionSelector: (item: T) => U[] | undefined): Array<U | undefined>;
export function flatMap<T, U, R>(items: T[], collectionSelector: (item: T) => U[] | undefined, resultSelector: (item: U) => R): Array<R | undefined>;

export function flatMap<T, U, R>(items: T[], collectionSelector: (item: T) => U[] | undefined, resultSelector?: (item: U) => R): Array<U | R | undefined> {
  return items.reduce((array, next) =>
    array.concat(collectionSelector(next)),
    [] as Array<U | undefined>,
  ).map(i => i ? (resultSelector ? resultSelector(i) : i) : undefined);
}

/**
 * Checks whether provided element is within the viewport.
 * @param {HTMLElement} element - the element to be checked.
 * @param {Window} window - the document window.
 * @param {number} threshold - the offset beyond which the element will be still considered in viewport.
 * @returns {{ full: boolean, partial: boolean }} The intersection result.
 */
export function getViewPortIntersection(element: HTMLElement, window: Window, threshold = 0) {
  if (!element)
    return {
      full: null,
      partial: null,
    };
  const bounding = element.getBoundingClientRect();
  const documentHeight = window.innerHeight;

  const result = {
    full: bounding.top >= -threshold && bounding.bottom <= documentHeight + threshold,
    partial: bounding.bottom >= -threshold && bounding.top <= documentHeight + threshold,
  };

  return result;
}

export function isValidDate(date?: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

/**
 * Fixes Chrome incorrect scroll anchoring behavior.
 */
export function fixIncorrectScrollAnchoring() {
  if (document.documentElement.scrollTop) {
    const { scrollTop } = document.documentElement;
    document.documentElement.scrollTop = scrollTop + 1;
    document.documentElement.scrollTop = scrollTop - 1;
  } else {
    const { scrollTop } = document.body;
    document.body.scrollTop = scrollTop + 1;
    document.body.scrollTop = scrollTop - 1;
  }
}

/**
 * Moves the item to the new position in the array.
 * @param {array} array - original array
 * @param {number} from - index of item to move
 * @param {number} to - index of where to move the item
 * @returns {array} - new array.
 */
export function arrayMove<T>(array: Array<T>, from: number, to: number): Array<T> {
  array = array.slice();
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
  return array;
}

interface WithImageSizes {
  small?: string;
  medium?: string;
  large?: string;
}

export function filterExistingImages<T extends WithImageSizes>(images: Array<T>): Array<T> {
  return images.filter(image => image.small || image.medium || image.large);
}

export function pad(number: number, count: number): string {
  return (number + 1e15 + '').slice(-count);
}

export function joinClasses(...arr: Array<string | boolean | null | undefined>): string | undefined {
  let res = '';
  arr.forEach(v => { v && (res += v + ' '); });
  return res || undefined;
}

const compare = new Intl.Collator(undefined, { sensitivity: 'accent' }).compare;

export function iEquals(x: string | null | undefined, y: string | null | undefined) {
  if (x == null || y == null)
    return x === y;

  return compare(x, y) === 0;
}

export function lazy<T>(factory: () => T) {
  let value: T;
  return {
    get value() {
      if (!value)
        value = factory();
      return value;
    },
  };
}

type MinValue = {
  desktop: string | null;
  tablet: string | null;
  mobile: string | null;
};

export function getMinValue(minValue: MinValue, xs: boolean, sm: boolean, md: boolean): string | null {
  if (xs)
    return minValue.mobile || minValue.tablet || minValue.desktop || null;
  if (sm || md)
    return minValue.tablet || minValue.desktop || null;

  return minValue.desktop || null;
}
