import { QuoteStatus } from 'behavior/documents';
import dateOnly from 'date-only';

export const isQuoteInStock = (document, stockLoaded = false) => {
  if (document.status !== QuoteStatus.Orderable)
    return undefined;

  const { totalCount, itemLines } = document.lines;

  // if stock was loaded - lines were loaded too.
  // if totalCount !== linesCount, it's an ERP bug mentioned in #89634
  if (!stockLoaded) {
    const linesCount = itemLines.reduce(
      (total, line) => total + (line.sublines && line.sublines.length || 1), 0);

    if (totalCount !== linesCount)
      return undefined;
  }

  const isOutOfStock = line => line.inStock === false;

  for (const line of itemLines) {
    if (isOutOfStock(line))
      return false;
    else if (line.sublines) {
      for (const subline of line.sublines) {
        if (isOutOfStock(subline))
          return false;
      }
    }
  }

  return true;
};

export const canInvoiceBePaid = invoice => {
  const {
    dueDate,
    isPaid,
    payment: { status: paymentStatus },
  } = invoice;

  if (dueDate && new Date(dueDate) < dateOnly.today())
    return false;

  // PaymentStatus from behavior/documents
  return !isPaid
    && paymentStatus !== 'inprogress'
    && paymentStatus !== 'paid';
};

export const setMarkedForReleaseToLines = (lines, linesReleased) => {
  if (!lines.itemLines || lines.itemLines.length <= 0)
    return lines;

  linesReleased.forEach(lineReleased => {
    setMarkedForReleaseToLine(lines.itemLines, lineReleased);
  });

  return lines;
};

const setMarkedForReleaseToLine = (lines, lineReleased) => {
  lines.forEach(line => {  
    if (line.sublines) {
      setMarkedForReleaseToLine(line.sublines, lineReleased);
      return;
    }
    
    if (line.lineNo === lineReleased)
      line.markedForRelease = true;
  });
};