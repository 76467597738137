exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewsletterSubscription_title{margin:0 0 .5rem}.NewsletterSubscription_title+.NewsletterSubscription_description{margin-top:.5rem}.NewsletterSubscription_description{margin-bottom:.5em}.NewsletterSubscription_form-wrapper{margin:0 -.15rem;position:relative}.NewsletterSubscription_form-wrapper:only-child{margin-top:-.5rem}.NewsletterSubscription_form{width:100%;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap}.NewsletterSubscription_form-field-wrapper{margin:.5rem .15rem 0;-webkit-flex:100 0 auto;flex:100 0 auto}.NewsletterSubscription_validation{padding:.4em 0 0 .15rem;line-height:1.2em;position:absolute;left:0;top:100%;font-size:var(--normalText_SmallerFontSize,12px);color:var(--validation_Error_FontColor,#cb2245)}.NewsletterSubscription_validation svg{margin-right:.5em}.NewsletterSubscription_btn-submit{-webkit-flex:1 1 auto;flex:1 1 auto;margin:.5rem .15rem 0;word-break:break-word}@supports (-ms-ime-align:auto){.NewsletterSubscription_btn-submit{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.NewsletterSubscription_btn-submit{word-wrap:break-word}}.NewsletterSubscription_btn-submit.NewsletterSubscription_vd-mode[disabled]{background-color:var(--buttons_BackgroundColor,#1f7bc9);color:var(--buttons_FontColor,#fff)}.NewsletterSubscription_container{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"title": "NewsletterSubscription_title",
	"description": "NewsletterSubscription_description",
	"form-wrapper": "NewsletterSubscription_form-wrapper",
	"formWrapper": "NewsletterSubscription_form-wrapper",
	"form": "NewsletterSubscription_form",
	"form-field-wrapper": "NewsletterSubscription_form-field-wrapper",
	"formFieldWrapper": "NewsletterSubscription_form-field-wrapper",
	"validation": "NewsletterSubscription_validation",
	"btn-submit": "NewsletterSubscription_btn-submit",
	"btnSubmit": "NewsletterSubscription_btn-submit",
	"vd-mode": "NewsletterSubscription_vd-mode",
	"vdMode": "NewsletterSubscription_vd-mode",
	"container": "NewsletterSubscription_container"
};